<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
//code
export default {
  name: "App",

  data: () => ({}),
  methods: {},
};
</script>
