import Vue from 'vue';
import Router from 'vue-router';

const routes = [];

const requireAuth = async(to, from, next) => {
    const data = JSON.parse(localStorage.getItem('user'));
    if (data.user) {
        next();
    } else {
        next('/login');
    }
};
routes.push({
    path: '/dashboard',
    component: () =>
        import ('../pages/Dealer/Dashboard'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/sales',
    component: () =>
        import ('../pages/Dealer/Sale/'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/goals',
    component: () =>
        import ('../pages/Dealer/Goals/'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/goal/:id',
    component: () =>
        import ('../pages/Dealer/Goals/user.vue'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/goal/:id/award',
    component: () =>
        import ('../pages/Dealer/Award/'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/goal/:id/ranking',
    component: () =>
        import ('../pages/Dealer/Goals/view_ranking.vue'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/call',
    component: () =>
        import ('../pages/Dealer/Call/'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/messages',
    component: () =>
        import ('../pages/Dealer/Message/'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/talks',
    component: () =>
        import ('../pages/Talks2')
});

routes.push({
    path: '/testes',
    component: () =>
        import ('../pages/Testes'),
    beforeEnter: requireAuth
});

//TODO
routes.push({
    path: "/tasks",
    component: () =>
        import ("../pages/Tasks"),
});

routes.push({
    path: "/task/:id",
    component: () =>
        import ("../pages/Tasks/viewTask.vue"),
});

routes.push({
  path: "/to_do_lists",
  component: () =>
      import ("../pages/ToDoList"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_lists/form/:id?",
  component: () =>
      import ("../pages/ToDoList/form.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path:"/to_do_projects/type/:type?",
  component: () =>
      import ("../pages/ToDoProject"),
  beforeEnter: requireAuth,
});

routes.push({
    path: "/to_do_project/:project_id/phases",
    component: () =>
        import ("../pages/ToDoProject/listPhases.vue"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/phases/:phase_id/tasks",
    component: () =>
        import ("../pages/ToDoProject/listTasks.vue"),
    beforeEnter: requireAuth,
});


routes.push({
  path: "/to_do_projects/form/:id?",
  component: () =>
      import ("../pages/ToDoProject/form.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_categories",
  component: () =>
      import ("../pages/ToDoCategory"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_categories/form/:id?",
  component: () =>
      import ("../pages/ToDoCategory/form.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_phases",
  component: () =>
      import ("../pages/ToDoPhase"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_phases/form/:to_do_project_id/:id?",
  component: () =>
      import ("../pages/ToDoPhase/form.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_tasks",
  component: () =>
      import ("../pages/ToDoTask"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_tasks/form/:to_do_phase_id/:id?",
  component: () =>
      import ("../pages/ToDoTask/form.vue"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_dashboard",
  component: () =>
      import ("../pages/ToDoDashboard"),
  beforeEnter: requireAuth,
});
routes.push({
  path: "/to_do_project_manager",
  component: () =>
      import ("../pages/ToDoProjectManager"),
  beforeEnter: requireAuth,
});

routes.push({
  path: "/to_do_project_manager/form/:id?",
  component: () =>
      import ("../pages/ToDoProjectManager/form.vue"),
  beforeEnter: requireAuth,
});

Vue.use(Router);
export default new Router({
    mode: 'history',
    routes
});