import Vue from 'vue';
import Router from 'vue-router';

const routes = [];

const requireAuth = async(to, from, next) => {
    const data = JSON.parse(localStorage.getItem('user'));
    if (data.user) {
        next();
    } else {
        next('/login');
    }
};
routes.push({
    path: '/posts',
    component: () =>
        import ('../pages/Post/admin.vue'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/unity/:id/scale',
    component: () =>
        import ('../pages/Supervisor/Scale'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/messages',
    component: () =>
        import ('../pages/Supervisor/Message/'),
    beforeEnter: requireAuth
});


routes.push({
    path: '/dashboard',
    component: () =>
        import ('../pages/Supervisor/Dashboard'),
    beforeEnter: requireAuth
});


// routes.push({
//     path: '/dashboard',
//     component: () =>
//         import ('../pages/Dash'),
//     beforeEnter: requireAuth
// });

routes.push({
    path: '/units',
    component: () =>
        import ('../pages/Supervisor/Units'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/unity/:id/users',
    component: () =>
        import ('../pages/Supervisor/Units/users'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/sales/:type/:id',
    component: () =>
        import ('../pages/Supervisor/Sale/'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/goals',
    component: () =>
        import ('../pages/Supervisor/Goals/'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/goal/:id',
    component: () =>
        import ('../pages/Supervisor/Goals/view.vue'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/goal/:id/award',
    component: () =>
        import ('../pages/Supervisor/Award/'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/call/:type/:id',
    component: () =>
        import ('../pages/Supervisor/Call/'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/goal/:id/ranking',
    component: () =>
        import ('../pages/Supervisor/Goals/view_ranking.vue'),
    beforeEnter: requireAuth
});


routes.push({
    path: '/cash_flow/:id',
    component: () =>
        import ('../pages/Supervisor/CashFlow/'),
    beforeEnter: requireAuth
});
routes.push({
    path: '/cash_flow/:id/list',
    component: () =>
        import ('../pages/Supervisor/CashFlow/list.vue'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/testes',
    component: () =>
        import ('../pages/Testes'),
    beforeEnter: requireAuth
});

routes.push({
    path: '/my_talks',
    component: () =>
        import ('../pages/Admin/Talks')
});

routes.push({
    path: '/talks',
    component: () =>
        import ('../pages/Talks2')
});

routes.push({
    path: '/my_email',
    component: () =>
        import ('../pages/Seller/MyEmail'),

});

routes.push({
    path: '/messages',
    component: () =>
        import ('../pages/Seller/Message/'),
    beforeEnter: requireAuth
});

//TODO
routes.push({
    path: "/tasks",
    component: () =>
        import ("../pages/Tasks"),
});

routes.push({
    path: "/task/:id",
    component: () =>
        import ("../pages/Tasks/viewTask.vue"),
});

routes.push({
    path: "/to_do_lists",
    component: () =>
        import ("../pages/ToDoList"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/to_do_lists/form/:id?",
    component: () =>
        import ("../pages/ToDoList/form.vue"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/to_do_projects/type/:type?",
    component: () =>
        import ("../pages/ToDoProject"),
    beforeEnter: requireAuth,
  });

  routes.push({
    path: "/to_do_project/:project_id/phases",
    component: () =>
        import ("../pages/ToDoProject/listPhases.vue"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/phases/:phase_id/tasks",
    component: () =>
        import ("../pages/ToDoProject/listTasks.vue"),
    beforeEnter: requireAuth,
});
  


routes.push({
    path: "/to_do_projects/form/:id?",
    component: () =>
        import ("../pages/ToDoProject/form.vue"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/to_do_categories",
    component: () =>
        import ("../pages/ToDoCategory"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/to_do_categories/form/:id?",
    component: () =>
        import ("../pages/ToDoCategory/form.vue"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/to_do_phases",
    component: () =>
        import ("../pages/ToDoPhase"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/to_do_phases/form/:to_do_project_id/:id?",
    component: () =>
        import ("../pages/ToDoPhase/form.vue"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/to_do_tasks",
    component: () =>
        import ("../pages/ToDoTask"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/to_do_tasks/form/:to_do_phase_id/:id?",
    component: () =>
        import ("../pages/ToDoTask/form.vue"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/to_do_dashboard",
    component: () =>
        import ("../pages/ToDoDashboard"),
    beforeEnter: requireAuth,
});
routes.push({
    path: "/to_do_project_manager",
    component: () =>
        import ("../pages/ToDoProjectManager"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/to_do_project_manager/form/:id?",
    component: () =>
        import ("../pages/ToDoProjectManager/form.vue"),
    beforeEnter: requireAuth,
});
  
Vue.use(Router);
export default new Router({
    mode: 'history',
    routes
});